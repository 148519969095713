import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Recipient } from 'src/app/modules/analytics/models/recipient';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { AnalyticsDataService } from 'src/app/modules/shared/services/analytics-data.service';
import { ReportShareEmailTemplate } from 'src/app/modules/analytics/models/report-share-email-template';
import { B2CUser, RegistrationUser } from 'src/app/modules/core/user-service/user.model';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { LoadingBarStatus, UserPreferenceType } from 'src/app/modules/shared/globals';
import { KeyFinancialEnums } from 'src/app/modules/shared/models/key-financial.enum';
import { CompanyHighlights } from 'src/app/modules/shared/models/company-highlights';
import { DefaultCurrency, PdfReportType, ReportType } from 'src/app/app.constants';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { CompanyReportHighlightsTypes } from 'src/app/modules/analytics/models/company-report-highlights-type';
import { FileManagerChangeEventType, FileManagerDocumentType } from 'src/app/modules/shared/models/capassa-file-manager.enum';
import { FileManagerDocumentContent, FileManagerDocumentResponse, FileManagerModel, FileManagerShareBudget } from
  'src/app/modules/shared/models/capassa-file-manager.model';
import { CapassaFileManagerDataService } from 'src/app/modules/shared/services/capassa-file-manager-data.service';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { DataRoomData } from 'src/app/modules/data-room/models/data-room-data';
import { UserPreference } from 'src/app/modules/shared/models/UserPreference';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { YearlyPeriodType } from 'src/app/modules/analytics/models/yearly-period-type';
import { Currency } from 'src/app/modules/shared/models/currency';

declare function initializeFileManagerData(): void;
declare function updateFileManagerData(source: FileManagerDocumentContent[], selectedFolderId: string): void;

interface Sections {
  [key: string]: boolean;
  executiveSummary: boolean;
  table: boolean;
  graphs: boolean;
  keyFinancials: boolean;
  companyHighlights: boolean;
}

type SectionKeys = 'executiveSummary' | 'table' | 'graphs' | 'keyFinancials' | 'companyHighlights';

@Component({
  selector: 'app-report-share',
  templateUrl: './report-share.component.html',
  styleUrls: ['./report-share.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReportShareComponent implements OnInit, OnDestroy {

  showLoader = false;
  recipient = '';
  message = '';
  emailPlaceHolder = 'Type receiver email address.';
  emailList: Recipient[] = [];
  currencySubscription: Subscription;
  selectedLanguageSubscription: Subscription;
  registrationUserSubscription: Subscription;
  appStateServiceSubscription: Subscription;
  companyImageUrlSubscription: Subscription;
  browserLanguage = '';
  translateSubscription: Subscription;
  userPreferenceSubscription: Subscription;
  profileImageUrlSubscription: Subscription;
  translations: any;
  @Output() shareBudgetData: EventEmitter<FileManagerShareBudget> = new EventEmitter();
  @Output() popupClose: EventEmitter<void> = new EventEmitter();
  @Input() reportTemplate: ReportShareEmailTemplate;
  @Input() reportType = ReportType.monthly;
  @Input() pdfReportType = PdfReportType.profitAndLoss;
  @Input() shareToDataRoom = false;
  @Input() isBudget = false;
  @Input() yearlyPeriodType = YearlyPeriodType.totalYear;
  showShareWindow = true;
  showErrorMessage = false;
  kpiPreferenceValues: any[] = [];
  kpiQuarterlyPreferenceValues: any[] = [];
  kpiYearlyPreferenceValues: any[] = [];
  keyFinancialList: any[] = [];
  keyFinancialQuarterlyList: any[] = [];
  keyFinancialYearlyList: any[] = [];
  title = '';
  description = '';
  companyImageUrl = '';
  profileImageUrl = '';
  capassaRoles = '';
  editor = ClassicEditor;
  companyHighlightsExpand = false;
  companyHighlights = '';
  companyHighlightsData: any = '<div></div>';
  editorConfigs = { toolbar: ['heading', '|', 'bold', 'italic', 'Link', '|', 'undo', 'redo', '|', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable'] };
  selectedDocumentId = '';
  selectedDocumentTitle = '';
  selectedParentId: string;
  selectedFolderId = '';
  registrationUser: RegistrationUser;
  dataRoomDocuments: FileManagerModel[] = [];
  dataRoomList: DataRoomData[] = [];
  userPreferences: UserPreference[] = [];
  emailDistributionListValues: any[] = [];
  allEmailDistributionListValues: any[] = [];
  emailDistributionListPreferenceId: string;
  loggedInB2CUserSubscription: Subscription;
  registrationUserId: string;
  @Input() accountId: string;
  distributionListExpand = false;
  recentEmailDistributionListValues: any[] = [];
  disableDistributionList = false;
  pdfReportTypeEnum = PdfReportType;
  detailedReport = false;
  selectedCurrency: Currency = DefaultCurrency;
  initialEmailList: Recipient[] = [];
  @Input() fromDate: Date | null = null;
  @Input() toDate: Date | null = null;
  activeTab: 'sharing' | 'content' = 'sharing';
  fileType: 'Excel' | 'PDF' = 'PDF';

  sections: Sections = {
    executiveSummary: false,
    table: false,
    graphs: false,
    keyFinancials: false,
    companyHighlights: false,
  };

  // Placeholder properties for template bindings
  executiveSummary: string;
  compareToLastYear: boolean;
  compareToLastMonth: boolean;
  compareBudget: boolean;
  compareRevenue: boolean;
  compareGrossProfit: boolean;
  compareOperatingProfit: boolean;
  compareNetProfit: boolean;
  compareNetFinancialIncome: boolean;
  compareCostOfSales: boolean;
  graphProfitLoss: boolean;
  graphCashBalance: boolean;
  graphRoeRoce: boolean;
  graphRoa: boolean;
  graphOperatingExpenditure: boolean;
  graphCurrentAssetsLiabilities: boolean;
  keyRevenue: boolean;
  keyGrossProfit: boolean;
  keyCostOfSales: boolean;
  keyWorkingCapital: boolean;
  keyCurrentRatio: boolean;
  keyQuickRatio: boolean;
  keyEbitMargin: boolean;
  keyEbitdaMargin: boolean;
  keyEmployeeCount: boolean;

  constructor(private userInterface: UserInterfaceService, private appStateService: AppStateService, private translate: TranslateService,
    private analyticsDataService: AnalyticsDataService, private accountDataService: AccountDataService, private fileManagerDataService: CapassaFileManagerDataService,
    private utilityService: UtilityService, private activeRoute: ActivatedRoute, private userDataService: UserDataService,
    private userInterfaceService: UserInterfaceService) {
    (window as any).onChangeEvent = this.onChangeEvent.bind(this);
  }

  ngOnInit(): void {
    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((b2CUser: B2CUser) => {
      if (b2CUser && b2CUser.registrationUserId) {
        this.registrationUserId = b2CUser.registrationUserId;
      }
    });

    this.keyFinancialList = this.initializeKeyFinancialList();
    this.keyFinancialQuarterlyList = this.initializeQuarterlyKeyFinancialList();
    this.keyFinancialYearlyList = this.initializeYearlyKeyFinancialList();
    this.getCompanyHighlights();

    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.translate.use(language);
      this.title = 'Share report';
      if (this.shareToDataRoom) { this.title = 'Share to data room'; }
      if (!this.shareToDataRoom) { this.description = 'You can share the report as a PDF or excel file using our mail sharing option with your team or download to your desktop.'; }
      if (this.shareToDataRoom) { this.initializeFileManager(); }
    });

    this.translateSubscription = this.translate.getTranslation(this.browserLanguage).subscribe((translationObj) => {
      this.translations = translationObj;
    });

    this.activeRoute.queryParams.subscribe(params => {
      this.selectedFolderId = params.selected ? params.selected : '';
    });

    this.registrationUserSubscription = this.appStateService.subscribeRegistrationUser()
      .subscribe((registrationUser: RegistrationUser) => {
        if (registrationUser.id) {
          this.registrationUser = registrationUser;
          this.reportTemplate.senderEmail = registrationUser.email;
          let senderName = '';
          if (registrationUser.firstName) {
            senderName = registrationUser.firstName + ' ';
            this.reportTemplate.senderAlias = registrationUser.firstName.substring(0, 1).toUpperCase();
          }

          if (registrationUser.lastName) {
            senderName += registrationUser.lastName;
            this.reportTemplate.senderAlias += registrationUser.lastName.substring(0, 1).toUpperCase();
          }
          this.reportTemplate.senderName = senderName.trim();
          this.profileImageUrl = registrationUser.profileImageUrl ?? '';

          const account = this.accountDataService.getSelectedAccount();
          this.companyImageUrl = account?.logo ?? '';
        }
      });

    this.companyImageUrlSubscription = this.appStateService.subscribeCompanyImage().subscribe((imageUrl: string) => {
      this.companyImageUrl = imageUrl;
    });

    this.profileImageUrlSubscription = this.appStateService.subscribeProfileImage().subscribe((imageUrl: string) => {
      this.profileImageUrl = imageUrl;
    });

    this.userPreferenceSubscription = this.appStateService.subscribeUserPreferences().subscribe(result => {
      this.userPreferences = result;
      const allEmailDistributionListPreference = result.find(r => r.preferenceType === UserPreferenceType.EmailDistributionList);
      if (allEmailDistributionListPreference && allEmailDistributionListPreference.preferenceValue) {
        this.initializeEmailDistributionList(allEmailDistributionListPreference);
      } else {
        this.userPreferenceSubscription = this.userDataService.getUserPreferences(this.registrationUserId).subscribe(result => {
          if (result) {
            const userPreference = result.find(k => k.preferenceType === UserPreferenceType.EmailDistributionList);
            if (userPreference) {
              this.initializeEmailDistributionList(userPreference);
            }
          }
        });
      }

      const keyFinancePref = result.find(k => k.preferenceType === UserPreferenceType.KeyFinancial && k.accountId === this.accountId);
      if (keyFinancePref && keyFinancePref.preferenceValue) {
        this.kpiPreferenceValues = JSON.parse(keyFinancePref.preferenceValue);
        this.setKeyFinancialSelections(this.kpiPreferenceValues.filter((x: any) => x.addToMonthlyReport));
      } else {
        this.kpiPreferenceValues = this.keyFinancialList.map(m => ({ indicator: m.key, value: m.value }));
        this.setKeyFinancialSelections(this.kpiPreferenceValues);
      }

      const keyFinanceQuarterlyPref = result.find(k => k.preferenceType === UserPreferenceType.QuarterReportKeyFinancial && k.accountId === this.accountId);
      if (keyFinanceQuarterlyPref && keyFinanceQuarterlyPref.preferenceValue) {
        this.kpiQuarterlyPreferenceValues = JSON.parse(keyFinanceQuarterlyPref.preferenceValue);
        this.setKeyFinancialQuarterlySelections(this.kpiQuarterlyPreferenceValues.filter((x: any) => x.addToMonthlyReport));
      } else {
        this.kpiQuarterlyPreferenceValues = this.keyFinancialQuarterlyList.map(m => ({ indicator: m.key, value: m.value }));
        this.setKeyFinancialQuarterlySelections(this.kpiQuarterlyPreferenceValues);
      }

      const keyFinanceYearlyPref = result.find(k => k.preferenceType === UserPreferenceType.YearlyReportKeyFinancial && k.accountId === this.accountId);
      if (keyFinanceYearlyPref && keyFinanceYearlyPref.preferenceValue) {
        this.kpiYearlyPreferenceValues = JSON.parse(keyFinanceYearlyPref.preferenceValue);
        this.setKeyFinancialYearlySelections(this.kpiYearlyPreferenceValues.filter((x: any) => x.addToMonthlyReport));
      } else {
        this.kpiYearlyPreferenceValues = this.keyFinancialYearlyList.map(m => ({ indicator: m.key, value: m.value }));
        this.setKeyFinancialYearlySelections(this.kpiYearlyPreferenceValues);
      }
    });

    this.currencySubscription = this.appStateService.subscribeSelectedCurrency().subscribe(currency => {
      this.selectedCurrency = currency;
    });
  }

  toggleSection(section: SectionKeys) {
    this.sections[section] = !this.sections[section];
  }

  setEmailPreferences(emailDistributionListValues: any[]): void {
    emailDistributionListValues.forEach((element) => {
      const emailRecipient = this.setRecipient(element.email);
      this.emailList.push(emailRecipient);
      this.initialEmailList.push(emailRecipient);
    })
  }

  private setRecipient(element: any): Recipient {
    const emailRecipient = new Recipient();
    emailRecipient.id = Guid.create().toString();
    emailRecipient.email = element;
    emailRecipient.isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(element);
    return emailRecipient;
  }

  setKeyFinancialYearlySelections(keyFinancialListSelected: any[]): void {
    if (keyFinancialListSelected && keyFinancialListSelected.length) {
      this.keyFinancialYearlyList = this.keyFinancialYearlyList.filter(f => keyFinancialListSelected.some(item => item.indicator === f.key));
    } else {
      this.keyFinancialYearlyList = [];
    }
  }

  setKeyFinancialQuarterlySelections(keyFinancialListSelected: any[]): void {
    if (keyFinancialListSelected && keyFinancialListSelected.length > 0) {
      this.keyFinancialQuarterlyList = this.keyFinancialQuarterlyList.filter(f => keyFinancialListSelected.some(item => item.indicator === f.key));
    } else {
      this.keyFinancialQuarterlyList = [];
    }
  }

  setKeyFinancialSelections(keyFinancialListSelected: any[]): void {
    if (keyFinancialListSelected && keyFinancialListSelected.length > 0) {
      this.keyFinancialList = this.keyFinancialList.filter(f => keyFinancialListSelected.some(item => item.indicator === f.key));
    } else {
      this.keyFinancialList = [];
    }
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) this.selectedLanguageSubscription.unsubscribe();
    if (this.registrationUserSubscription) this.registrationUserSubscription.unsubscribe();
    if (this.appStateServiceSubscription) this.appStateServiceSubscription.unsubscribe();
    if (this.userPreferenceSubscription) this.userPreferenceSubscription.unsubscribe();
    if (this.companyImageUrlSubscription) this.companyImageUrlSubscription.unsubscribe();
    if (this.profileImageUrlSubscription) this.profileImageUrlSubscription.unsubscribe();
    if (this.loggedInB2CUserSubscription) this.loggedInB2CUserSubscription.unsubscribe();
    if (this.currencySubscription) { this.currencySubscription.unsubscribe(); }
  }

  addEmail(): void {
    if (this.recipient.trim() !== '') {
      const emailRecipient = this.setRecipient(this.recipient);
      this.emailList.push(emailRecipient);
      this.filterExistingEmails();
      this.recipient = '';
      this.setEmailPlaceHolderMessage();
    }
  }

  removeEmail(id: string): void {
    this.emailList = this.emailList.filter(f => f.id !== id);
    this.setEmailPlaceHolderMessage();
    if (this.distributionListExpand) {
      this.getUserPreferences();
    }
  }

  getUserPreferences(): void {
    this.userDataService.getUserPreferences(this.registrationUserId).subscribe(result => {
      if (!result) { return; }
      const userPreference = result.find(k => k.preferenceType === UserPreferenceType.EmailDistributionList);
      if (userPreference) {
        this.initializeEmailDistributionList(userPreference);
        this.filterExistingEmails();
      }
    });
  }

  filterExistingEmails(): void {
    if (this.distributionListExpand && this.recentEmailDistributionListValues.length && this.emailList.length) {
      this.recentEmailDistributionListValues = this.recentEmailDistributionListValues.filter((item) => !this.emailList.some((x) => x.email === item));
    }
  }

  checkAllEmailsExist(): boolean {
    if (this.distributionListExpand && this.initialEmailList.length && this.emailList.length) {
      const allEmailsInInitialListExist = this.initialEmailList.every((item) =>
        this.emailList.some((x) => x.email === item.email)
      );

      const allEmailsInEmailListExist = this.emailList.every((x) =>
        this.initialEmailList.some((item) => item.email === x.email)
      );
      return allEmailsInInitialListExist && allEmailsInEmailListExist;
    }
    return false;
  }

  disableSubmit(): boolean {
    if (this.emailList.length > 0 && this.emailList.filter(f => f.isValid === false).length === 0 && !this.checkAllEmailsExist()) {
      return false;
    } else {
      return true;
    }
  }

  disableSend(): boolean {
    if (this.emailList.length > 0 && this.emailList.filter(f => f.isValid === false).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  setEmailPlaceHolderMessage(): void {
    if (this.emailList.length > 0) {
      this.emailPlaceHolder = 'Add another';
    } else {
      this.emailPlaceHolder = 'Type receiver email address.';
    }
  }

  getCompanyHighlights(): void {
    let companyReportHighlightsTypes;
    switch (this.pdfReportType) {
      case PdfReportType.profitAndLoss:
        companyReportHighlightsTypes = CompanyReportHighlightsTypes.Monthly;
        break;
      case PdfReportType.balanceSheet:
        companyReportHighlightsTypes = CompanyReportHighlightsTypes.BalanceSheetMonthly;
        break;
      default:
        companyReportHighlightsTypes = CompanyReportHighlightsTypes.LiquidityMonthly;
        break;
    }

    if (this.reportType === ReportType.monthly) {
      const date = this.reportTemplate.reportDate ? new Date(this.reportTemplate.reportDate) : new Date();
      this.analyticsDataService.getCompanyHighlightsForMonthlyReport(date, companyReportHighlightsTypes).subscribe((result: CompanyHighlights) => {
        this.companyHighlights = result?.highlights ?? '';
      });
    }
    else {
      companyReportHighlightsTypes = this.pdfReportType === PdfReportType.profitAndLoss ? CompanyReportHighlightsTypes.Quarterly :
        CompanyReportHighlightsTypes.BalanceSheetQuarterly;
      this.analyticsDataService.getCompanyHighlightsForQuarterlyReport(this.reportTemplate.quarter, this.reportTemplate.quarterYear, companyReportHighlightsTypes).
        subscribe((result: CompanyHighlights) => {
          this.companyHighlights = result?.highlights ?? '';
        });
    }
  }

  shareBudget(): void {
    this.shareBudgetData.emit({
      key: this.selectedDocumentId,
      dataRoomId: !this.selectedParentId ? this.selectedDocumentId : this.selectedParentId,
      title: this.selectedDocumentTitle
    });
  }

  buildReportTemplate(): void {
    this.reportTemplate.recipientEmails = this.emailList.map(i => i.email);
    this.reportTemplate.messageBody = this.message;
    this.reportTemplate.companyLogoUrl = this.companyImageUrl;
    this.reportTemplate.userProfileUrl = this.profileImageUrl;
    this.reportTemplate.userRoles = this.capassaRoles;
    this.reportTemplate.shareToDataRoom = this.shareToDataRoom;
    this.reportTemplate.dataRoomDocumentId = this.shareToDataRoom ? this.selectedDocumentId : Guid.EMPTY;
    this.reportTemplate.createdBy = this.registrationUser?.id ?? Guid.EMPTY;
    this.reportTemplate.dataRoomId = !this.selectedParentId ? this.selectedDocumentId : this.selectedParentId;
    this.reportTemplate.dataRoomId = !this.selectedParentId ? Guid.EMPTY : this.selectedParentId;
    this.reportTemplate.selectedCurrencyRate = this.selectedCurrency.rate;
    this.reportTemplate.selectedCurrencySymbol = this.selectedCurrency.symbol;
  }

  executeReportSharing(endPoint :any): void{
    endPoint.call(this.analyticsDataService, this.reportTemplate).subscribe(() => {
      this.showLoader = false;
      this.closePopup();
      if (this.shareToDataRoom) {
        const folderName = this.selectedDocumentId !== this.selectedParentId ? this.dataRoomDocuments?.find(x => x.id === this.selectedDocumentId)?.name ?? '' :
          this.dataRoomList?.find(x => x.id === this.selectedParentId)?.name ?? '';
        this.userInterface.showCommonSuccessMessage('upload-successful', this.translations['Uploaded successfully'],
          `${this.reportTemplate.fileName} ${this.translations['has been successfully uploaded to']} ${folderName}`);
        return;
      }
      this.userInterface.showCommonSuccessMessage('icon-sharing-successful', this.translations['Sharing successful!'],
        this.translations['The Report has been shared successfully to all recipients.']);
    }, (error: any) => {
      this.showLoader = false;
      this.showShareWindow = false;
      this.showErrorMessage = true;
    });
  }

  sharePDFReport(): void {
    if (this.shareToDataRoom && !this.showSameFileNameWarning(this.getReportFileName())) {
      this.showShareWindow = false;
      this.closePopup();
      return;
    }
    this.showLoader = true;
    let companyHighlight = '';
    let endPoint = this.pdfReportType === PdfReportType.profitAndLoss ? this.analyticsDataService.shareQuarterlyReport : this.analyticsDataService.shareQuarterlyBalanceSheetReport;
    this.buildReportTemplate();
    this.reportTemplate.financialKeys = [
      KeyFinancialEnums.grossProfit, KeyFinancialEnums.grossProfitMargin, KeyFinancialEnums.operatingProfitMargin,
      KeyFinancialEnums.ebitda, KeyFinancialEnums.ebitdaMargin, KeyFinancialEnums.ebit, KeyFinancialEnums.ebitMargin,
      KeyFinancialEnums.netProfitMargin, KeyFinancialEnums.roa, KeyFinancialEnums.assets, KeyFinancialEnums.cashAndCashEquivalents,
      KeyFinancialEnums.equity, KeyFinancialEnums.workingCapital, KeyFinancialEnums.freeCashFlow, KeyFinancialEnums.nibdCash,
      KeyFinancialEnums.currentRatio, KeyFinancialEnums.quickRatio, KeyFinancialEnums.undistributedProfitLoss,
      KeyFinancialEnums.burnRate, KeyFinancialEnums.cashRunway, KeyFinancialEnums.debtServiceCoverageRatio,
      KeyFinancialEnums.interestCoverageRatio, KeyFinancialEnums.employeeCount, KeyFinancialEnums.roe, KeyFinancialEnums.roce];

    if (this.companyHighlightsExpand) {
      companyHighlight = this.companyHighlights ? this.companyHighlights.split('<p>&nbsp;</p>').join('<br>') : this.companyHighlights;
    }

    this.reportTemplate.companyHighlights = companyHighlight;
    this.reportTemplate.fileName = this.getReportFileName();
    this.reportTemplate.detailedReport = this.detailedReport;
    this.reportTemplate.landscapeReport = this.reportType === ReportType.yearly && this.yearlyPeriodType === YearlyPeriodType.monthOnMonth;

    switch (this.reportType) {
      case ReportType.monthly:
        this.reportTemplate.financialKeys = this.keyFinancialList.map(x => x.value);
        switch (this.pdfReportType) {
          case PdfReportType.profitAndLoss:
            endPoint = this.analyticsDataService.shareMonthlyReport;
            break;
          case PdfReportType.balanceSheet:
            endPoint = this.analyticsDataService.shareMonthlyBalanceSheetReport;
            break;
          case PdfReportType.liquidity:
            endPoint = this.analyticsDataService.shareMonthlyLiquidityReport;
            break;
        }
        break;
      case ReportType.yearly:
        this.reportTemplate.financialKeys = this.keyFinancialYearlyList.map(x => x.value);
        if (this.reportTemplate.landscapeReport && this.fromDate !== null && this.toDate !== null) {
          this.reportTemplate.fromDate = this.utilityService.getLocalDate(this.fromDate, 'en', 'YYYY-MM-DD');
          this.reportTemplate.toDate = this.utilityService.getLocalDate(this.toDate, 'en', 'YYYY-MM-DD');
        } else {
          const defaultDateString = "0001-01-01T00:00:00";
          this.reportTemplate.fromDate = defaultDateString;
          this.reportTemplate.toDate = defaultDateString;
        }
        endPoint = this.analyticsDataService.shareYearlyReport;
        break;
      default:
        this.reportTemplate.financialKeys = this.keyFinancialQuarterlyList.map(x => x.value);
        break;
    }

    this.executeReportSharing(endPoint);
  }

  shareExcelReport(): void {
    this.buildReportTemplate();
    const reportDate = new Date(this.reportTemplate.reportDate);
    reportDate.setMonth(reportDate.getMonth() - 1);
    const month = this.reportTemplate.reportDate ? this.utilityService.getLocalDate(reportDate, this.browserLanguage, 'MMM') :
      this.utilityService.getLocalDate(new Date(), this.browserLanguage, 'MMM');
    const year = this.reportTemplate.reportDate ? new Date(reportDate).getFullYear() : new Date().getFullYear();
    const createdMonth = month ? month.replace('.', '') : month;

    let reportTypeTranslation = this.translations['Profit and loss'];
    let reportName = `${reportTypeTranslation} - `;
    let endPoint: any;

    switch (this.reportType) {
      case ReportType.monthly:
        endPoint = this.analyticsDataService.shareMonthlyPAndLReportExcel
        reportName += `${createdMonth} ${year}.xls`;
        break;
      case ReportType.quarterly:
        this.reportTemplate.quarter = this.reportTemplate.quarter ? this.reportTemplate.quarter : this.utilityService.getQuarterByDate(new Date());
        this.reportTemplate.quarterYear = this.reportTemplate.quarterYear ? this.reportTemplate.quarterYear : new Date(reportDate).getFullYear();
        endPoint = this.analyticsDataService.shareQuarterlyPAndLReportExcel;
        reportName += `Q${this.reportTemplate.quarter} ${this.reportTemplate.quarterYear}.xls`;
        break;
      default:
        reportName += `${year}.xls`;
        endPoint = this.analyticsDataService.shareYearlyPAndLReportExcel
        break;
    }

    this.reportTemplate.fileName = reportName;
    this.executeReportSharing(endPoint);
  }

  getReportFileName(): string {
    const reportDate = new Date(this.reportTemplate.reportDate);
    reportDate.setMonth(reportDate.getMonth() - 1);
    const month = this.reportTemplate.reportDate ? this.utilityService.getLocalDate(reportDate, this.browserLanguage, 'MMM') :
      this.utilityService.getLocalDate(new Date(), this.browserLanguage, 'MMM');
    const year = this.reportTemplate.reportDate ? new Date(reportDate).getFullYear() : new Date().getFullYear();
    const createdMonth = month ? month.replace('.', '') : month;
    const detailed = this.detailedReport ? ` - ${this.translations['Detailed']}` : '';
    if (this.reportType === ReportType.monthly) {
      let reportTypeTranslation: string;
      switch (this.pdfReportType) {
        case PdfReportType.profitAndLoss:
          reportTypeTranslation = this.translations['Profit and loss'];
          break;
        case PdfReportType.balanceSheet:
          reportTypeTranslation = this.translations['Balance sheet'];
          break;
        case PdfReportType.liquidity:
          reportTypeTranslation = this.translations['Liquidity'];
          break;
        default:
          reportTypeTranslation = '';
          break;
      }

      return `${reportTypeTranslation} - ${createdMonth} ${year}${detailed}.pdf`;
    }
    else if (this.reportType === ReportType.yearly) {
      const yearlyType = this.yearlyPeriodType == YearlyPeriodType.totalYear ? this.translations['Total year'] :
        this.fromDate !== null && this.toDate !== null ? `${this.utilityService.getLocalDate(new Date(year, this.fromDate.getMonth(), 1), this.browserLanguage, 'MMM').replace('.', '')} ${this.translations['to']} ${this.utilityService.getLocalDate(new Date(year, this.toDate.getMonth(), 1), this.browserLanguage, 'MMM').replace('.', '')}` :
          this.translations['Month on month'];
      return `${this.pdfReportType === PdfReportType.profitAndLoss ? this.translations['Profit and loss'] : this.translations['Balance sheet']} - ${yearlyType} ${year}${detailed}.pdf`;
    }
    else {
      const quarter = this.reportTemplate.quarter ? this.reportTemplate.quarter : this.utilityService.getQuarterByDate(new Date());
      const quarterYear = this.reportTemplate.quarterYear ? this.reportTemplate.quarterYear : new Date(reportDate).getFullYear();
      return `${this.pdfReportType === PdfReportType.profitAndLoss ? this.translations['Profit and loss'] : this.translations['Balance sheet']} - Q${quarter} ${quarterYear}${detailed}.pdf`;
    }
  }

  closePopup(): void {
    this.popupClose.emit();
  }

  closeErrorModal(): void {
    this.showErrorMessage = false;
    this.showShareWindow = true;
    this.closePopup();
  }

  shareAgain(): void {
    this.showShareWindow = true;
    this.showErrorMessage = false;
  }

  initializeKeyFinancialList(): any {
    return Object.keys(KeyFinancialEnums)
      .filter((v) => isNaN(Number(v))).map((key) => {
        return {
          key,
          value: KeyFinancialEnums[key as keyof typeof KeyFinancialEnums],
        };
      });
  }

  showSameFileNameWarning(fileName: string): boolean {
    if (this.dataRoomDocuments && this.dataRoomDocuments.length) {
      const document = !this.dataRoomDocuments.find(x => x.id === this.selectedDocumentId) ?
        this.dataRoomDocuments.find(x => x.dataRoomId === this.selectedDocumentId) :
        this.dataRoomDocuments.find(x => x.id === this.selectedDocumentId);
      const documents = this.dataRoomDocuments.find(x => x.id === this.selectedDocumentId) ? this.dataRoomDocuments.filter(x => x.parentId === this.selectedDocumentId) :
        this.dataRoomDocuments.filter(x => x.dataRoomId === document?.dataRoomId);
      if (!documents || !documents.length) { return true; }
      const existingFile = documents.find(x => x.name === fileName);
      if (existingFile) {
        this.userInterface.showCommonErrorMessage('', 'File with the same name already exists.', this.translations['Close']);
        return false;
      }
    }
    return true;
  }

  initializeQuarterlyKeyFinancialList(): any {
    const keyFinancials = [
      KeyFinancialEnums.grossProfit, KeyFinancialEnums.grossProfitMargin, KeyFinancialEnums.operatingProfitMargin,
      KeyFinancialEnums.ebitda, KeyFinancialEnums.ebitdaMargin, KeyFinancialEnums.ebit, KeyFinancialEnums.ebitMargin,
      KeyFinancialEnums.netProfitMargin, KeyFinancialEnums.roa, KeyFinancialEnums.assets, KeyFinancialEnums.cashAndCashEquivalents,
      KeyFinancialEnums.equity, KeyFinancialEnums.workingCapital, KeyFinancialEnums.freeCashFlow,
      KeyFinancialEnums.debtServiceCoverageRatio, KeyFinancialEnums.interestCoverageRatio, KeyFinancialEnums.employeeCount,
      KeyFinancialEnums.roe, KeyFinancialEnums.roce
    ];
    return Object.keys(KeyFinancialEnums)
      .filter((v) => isNaN(Number(v)) && keyFinancials.includes(KeyFinancialEnums[v as keyof typeof KeyFinancialEnums]))
      .map((key) => {
        return {
          key,
          value: KeyFinancialEnums[key as keyof typeof KeyFinancialEnums],
        };
      });
  }

  initializeYearlyKeyFinancialList(): any {
    const keyFinancials = [
      KeyFinancialEnums.grossProfit, KeyFinancialEnums.grossProfitMargin, KeyFinancialEnums.operatingProfitMargin,
      KeyFinancialEnums.ebitda, KeyFinancialEnums.ebitdaMargin, KeyFinancialEnums.ebit, KeyFinancialEnums.ebitMargin,
      KeyFinancialEnums.netProfitMargin, KeyFinancialEnums.roa, KeyFinancialEnums.assets, KeyFinancialEnums.cashAndCashEquivalents,
      KeyFinancialEnums.equity, KeyFinancialEnums.workingCapital, KeyFinancialEnums.freeCashFlow, KeyFinancialEnums.employeeCount,
      KeyFinancialEnums.roe, KeyFinancialEnums.roce
    ];
    return Object.keys(KeyFinancialEnums)
      .filter((v) => isNaN(Number(v)) && keyFinancials.includes(KeyFinancialEnums[v as keyof typeof KeyFinancialEnums]))
      .map((key) => {
        return {
          key,
          value: KeyFinancialEnums[key as keyof typeof KeyFinancialEnums],
        };
      });
  }

  onChangeEvent(type: string, data: any): void {
    switch (type) {
      case FileManagerChangeEventType.SelectedDocument:
        this.selectedDocumentId = data.key;
        this.selectedParentId = data.parentId;
        this.selectedDocumentTitle = data.title;
        break;
    }
  }

  initializeFileManager(): void {
    initializeFileManagerData();
    this.getDocumentList();
  }

  getDocumentList(): void {
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
    this.fileManagerDataService.getDocumentData().subscribe((data: FileManagerDocumentResponse) => {
      if (data.dataRoomDocuments && data.dataRoomDocuments.length) {
        this.dataRoomDocuments = data.dataRoomDocuments;
        this.dataRoomList = data.dataRoomList;
        const documentTransformList = this.transformData(data.dataRoomDocuments, data.dataRoomList);
        updateFileManagerData(documentTransformList, this.selectedFolderId);
      }
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    }, error => {
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    });
  }

  transformData(documents: FileManagerModel[], dataRoomIds: DataRoomData[], browserLanguage = 'en-US'): FileManagerDocumentContent[] {
    if (!documents || !documents.length) { return []; }

    const result: FileManagerDocumentContent[] = [];
    const documentChildren: FileManagerDocumentContent[] = [];
    const documentDict: any = {};
    const rootId = 'root';

    documents.forEach(document => {
      const fileSize = this.utilityService.getFileSize(document.fileSize);
      const parentId = document.parentId ? document.parentId : rootId;
      documentDict[document.id] = new FileManagerDocumentContent(document.id, document.name, (document.type === 1),
        document.type, '', document.modifiedByUserName, fileSize, [], parentId, document.dataRoomId);
    });

    documents.forEach(document => {
      if (document.parentId === null) {
        documentChildren.push(documentDict[document.id]);
      } else {
        documentDict[document.parentId]?.children?.push(documentDict[document.id]);
      }
    });

    if (!dataRoomIds || !dataRoomIds.length) {
      const dataRoomDetails = { ...new DataRoomData(), id: Guid.EMPTY, name: documents[0]?.dataRoom?.name ?? '' };
      dataRoomIds = [];
      dataRoomIds.push(dataRoomDetails);
    }

    this.selectedDocumentId = this.selectedDocumentId ? this.selectedDocumentId : dataRoomIds[0]?.id ?? '';

    dataRoomIds.forEach((item: DataRoomData) => {
      const children: FileManagerDocumentContent[] = documentChildren?.reduce((accumulator: FileManagerDocumentContent[], value: FileManagerDocumentContent) => {
        if (value.dataRoomId === item.id) {
          accumulator.push({ ...value });
        }
        return accumulator;
      }, []) ?? [];
      result.push(new FileManagerDocumentContent(item.id, item.name, true,
        FileManagerDocumentType.Folder, '', '', '', children, null, ''));
    });
    return result;
  }

  updateEmailDistributionListPreference(): void {
    this.disableDistributionList = true;
    this.emailDistributionListValues = [];
    this.emailDistributionListValues = this.emailList.map(e => ({ email: e.email, isActive: true }));

    const emailsToAdd = this.emailDistributionListValues.filter(e => !this.allEmailDistributionListValues.some(a => a.email === e.email));
    if (emailsToAdd && emailsToAdd.length > 0) {
      this.allEmailDistributionListValues.push(...emailsToAdd);
    }


    const updatedEmailDistributionList: any[] = [];
    this.allEmailDistributionListValues.forEach(a => {
      if (this.emailDistributionListValues.some(e => e.email === a.email)) {
        updatedEmailDistributionList.push({ email: a.email, isActive: true })
      } else {
        updatedEmailDistributionList.push({ email: a.email, isActive: false })
      }
    })

    if (updatedEmailDistributionList && updatedEmailDistributionList.length > 0) {
      this.allEmailDistributionListValues = updatedEmailDistributionList;
    } else {
      this.allEmailDistributionListValues = this.emailDistributionListValues;
    }

    const emailDistributionListUserPreference = new UserPreference();
    emailDistributionListUserPreference.preferenceId = this.emailDistributionListPreferenceId;
    emailDistributionListUserPreference.userId = this.registrationUserId;
    emailDistributionListUserPreference.accountId = this.accountId;
    emailDistributionListUserPreference.preferenceType = UserPreferenceType.EmailDistributionList;
    emailDistributionListUserPreference.preferenceValue = JSON.stringify(this.allEmailDistributionListValues);

    this.userDataService.updateUserPreference(emailDistributionListUserPreference).subscribe((result: UserPreference) => {
      this.emailDistributionListPreferenceId = result.preferenceId;
      emailDistributionListUserPreference.preferenceId = this.emailDistributionListPreferenceId;
      this.initialEmailList = this.emailList;
      this.publishUserPreference(emailDistributionListUserPreference, UserPreferenceType.EmailDistributionList);
      this.filterExistingEmails();
      this.disableDistributionList = false;
    }, () => {
      this.disableDistributionList = false;
      this.userInterface.showCommonErrorMessage();
    });
  }

  publishUserPreference(userPreference: UserPreference, userPreferenceType: UserPreferenceType): void {
    this.userPreferences = this.userPreferences.filter(item => item.preferenceType !== userPreferenceType);
    this.userPreferences.push(userPreference);
    this.appStateService.publishUserPreferences(this.userPreferences);
  }


  distributionlListToggleSection(expand: boolean): void {
    this.distributionListExpand = !expand;
    if (this.distributionListExpand) {
      this.emailList = [];
      this.initialEmailList = [];
      this.getUserPreferences();
      this.setEmailPreferences(this.emailDistributionListValues);
      this.setEmailPlaceHolderMessage();
    } else {
      this.emailList = [];
      this.initialEmailList = [];
      this.setEmailPlaceHolderMessage();
    }
  }

  initializeEmailDistributionList(allEmailDistributionListPreference: any): any {
    this.emailDistributionListPreferenceId = allEmailDistributionListPreference.preferenceId;
    this.allEmailDistributionListValues = JSON.parse(allEmailDistributionListPreference.preferenceValue);
    this.emailDistributionListValues = this.allEmailDistributionListValues.filter(a => a.isActive);
    this.recentEmailDistributionListValues = [...new Set((this.allEmailDistributionListValues).map(f => f.email).reverse())];
  }

  toggleDetailedReportSection(detailedReport: boolean): void {
    this.detailedReport = !detailedReport;
  }

  downloadReport(): void {
    if (this.fileType == 'Excel') {
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
      const reportDate = new Date(this.reportTemplate.reportDate);
      reportDate.setMonth(reportDate.getMonth() - 1);
      const month = this.reportTemplate.reportDate ? this.utilityService.getLocalDate(reportDate, this.browserLanguage, 'MMM') :
        this.utilityService.getLocalDate(new Date(), this.browserLanguage, 'MMM');
      const year = this.reportTemplate.reportDate ? new Date(reportDate).getFullYear() : new Date().getFullYear();
      const createdMonth = month ? month.replace('.', '') : month;

      let reportTypeTranslation = this.translations['Profit and loss'];
      let reportName = `${reportTypeTranslation} - `;
      let endPoint: Observable<any>;

      switch (this.reportType) {
        case ReportType.monthly:
          endPoint = this.analyticsDataService.downloadMonthlyPAndLReportExcel(reportDate);
          reportName += `${createdMonth} ${year}.xls`;
          break;
        case ReportType.quarterly:
          const quarter = this.reportTemplate.quarter ? this.reportTemplate.quarter : this.utilityService.getQuarterByDate(new Date());
          const quarterYear = this.reportTemplate.quarterYear ? this.reportTemplate.quarterYear : new Date(reportDate).getFullYear();
          endPoint = this.analyticsDataService.downloadQuarterlyPAndLReportExcel(quarter, quarterYear);
          reportName += `Q${quarter} ${quarterYear}.xls`;
          break;
        default:
          reportName += `${year}.xls`;
          endPoint = this.analyticsDataService.downloadYearlyPAndLReportExcel(year);
          break;
      }

      endPoint.subscribe(data => {
        this.downloadSummaryExcel(data, reportName);
      }, error => {
        this.userInterfaceService.showCommonErrorMessage();
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
      });
    }
  }

  downloadSummaryExcel(data: any, fileName: string): void {
    if (data && data.size) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.utilityService.downloadResource(blob, fileName);
      this.closePopup();
    } else {
      this.userInterfaceService.showCommonErrorMessage();
    }
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
  }
}
