import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
  AccountInfo,
  SsoSilentRequest
} from '@azure/msal-browser';
import { IdTokenClaims, PromptValue } from '@azure/msal-common'
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { authConfig } from 'src/app/modules/core/auth/auth.config';
import { Constants, RouteLinkConstants } from 'src/app/app.constants';
import { Account } from 'src/app/modules/shared/models/account';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { B2CUser, RegistrationUser } from 'src/app/modules/core/user-service/user.model';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { AccountingSystemEnum, LoadingBarStatus, OnboardingContainerState, UserFlow, UserAction, UserPreferenceType, Device } from 'src/app/modules/shared/globals';
import { UserFlowService } from 'src/app/modules/shared/services/user-flow.service';
import { LanguageDataService } from 'src/app/modules/shared/services/language-data.service';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { BrowserStateService } from 'src/app/modules/shared/services/browser-state.service';
import { UserService } from 'src/app/modules/core/user-service/user-service';
import { UserIdleService } from 'angular-user-idle';
import { SignalRMessageSubscriberService } from 'src/app/modules/core/common-services/signalr-message-subscriber.service';
import { AccountingSystemIntegration } from 'src/app/modules/shared/models/accounting-system-status';
import { AccountingSystemIntegrationStatus } from 'src/app/modules/shared/models/accounting-system-integration-status';
import { AccountIntegrationStatus } from 'src/app/modules/shared/models/account-integration-status';
import { AccountIntegrationStatusEnum } from 'src/app/modules/shared/models/account-integration-status.enum';
import { GlobalNotification } from 'src/app/modules/core/models/notification.model';
import { NotificationType } from 'src/app/modules/core/models/notification-type';
import { NotificationKeys } from 'src/app/modules/core/models/notification-keys';
import { SyncStatus } from 'src/app/modules/core/models/sync-status';
import { SelectedCompany } from 'src/app/models/selectedCompany';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { UserPermissionService } from 'src/app/modules/shared/services/user-permission.service';
import { AccountUser } from 'src/app/modules/shared/models/account-user';
import { environment } from 'src/environments/environment';
import { AccountingSystem } from 'src/app/modules/shared/models/accounting-system';
import { LostConnectionPopUpComponent } from 'src/app/modules/shared/components/lost-connection-pop-up/lost-connection-pop-up.component';
import { AccountingSystemDataService } from 'src/app/modules/shared/services/accounting-system-data.service';
import { DeviceService } from 'src/app/modules/core/common-services/device.service';
import { GoogleAnalyticsTagsManagerService } from 'src/app/modules/shared/services/google-analytics-tags-manager.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { SubscriptionTypes } from 'src/app/modules/shared/models/subscription-types.enum';
import { AccountSubscriptionHistory } from 'src/app/modules/shared/models/account-subscription-history';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'CustomerWebPortal';
  loggedIn = false;
  isLoading = false;
  isOverlay = false;
  showAccounts = true;
  showResponsiveLeftMenu = false;
  showFullScreen = false;
  isWidget = false;
  canViewIntegrations = false;
  checkedActiveConnection = false;
  userIdentifier = '';
  languageCode = 'en_US';
  profile: object;
  accountingSystemName: string;
  containerState: number;
  constants = Constants;
  registrationB2cUser: B2CUser;
  onBoardedAccounts: Account[];
  selectedAccount: Account;
  userFlow: UserFlow = UserFlow.SignIn;
  selectedSection = this.constants.home;
  registrationUserId: string;
  onMasterMenuItemSelected: (selectedMenu: string) => void;
  onboardCompanyAccountSubscription: Subscription;
  firebaseTokenSubscription: Subscription;
  registrationB2CUserSubscription: Subscription;
  userActionSubscription: Subscription;
  pleaseWaitText = 'Please wait';
  weAreConnectingText = 'We are connecting you';
  leftMenuCollapsed = false;
  private readonly asyncHandler = new Subject<void>();
  mobileRendering = false;
  environment = environment;
  displayChatBox = false;
  @ViewChild('modalNoCompany') noCompanyMessage: TemplateRef<any>;
  @ViewChild('modalTrialExpiryMessage') modalTrialExpiryMessage!: TemplateRef<any>;
  private overlayRef: OverlayRef;
  isCopilotMinimized: boolean = true;
  capassaRegistrationUser: RegistrationUser;
  copilotUrl: SafeResourceUrl;
  activeSubscription: Subscription;

  constructor(readonly msalBroadcastService: MsalBroadcastService, readonly msalService: MsalService, readonly authService: AuthService,
    readonly translate: TranslateService, readonly appStateService: AppStateService, readonly userDataService: UserDataService,
    readonly userInterfaceService: UserInterfaceService, readonly userFlowService: UserFlowService, readonly activeRoute: ActivatedRoute,
    readonly languageService: LanguageDataService, readonly router: Router, readonly browserStateService: BrowserStateService,
    readonly userService: UserService, readonly userIdle: UserIdleService, readonly signalRMessageSubscriberService: SignalRMessageSubscriberService,
    readonly accountDataService: AccountDataService, readonly userPermissionService: UserPermissionService,
    readonly titleService: Title, @Inject(DOCUMENT) readonly document: Document, readonly deviceService: DeviceService,
    readonly accountingSystemDataService: AccountingSystemDataService, private overlay: Overlay, readonly googleAnalyticsTagsManagerService: GoogleAnalyticsTagsManagerService,
    readonly modalService: NgbModal, readonly sharedStorageService: SharedStorageService, readonly sanitizer: DomSanitizer) {
    translate.addLangs(['en', 'nb']);
  }

  ngOnInit(): void {
    //To do: Uncomment later after addressing performance issue in when posting google analytics
    //this.setGTagManager();
    //this.handleRouteEvents();
    this.localeOverride();
    this.loadCurrentLanguage();
    const url = window.location.href;
    this.isWidget = url.includes('capassa-score');
    if (!this.isWidget) {
      this.userService.updateInitialB2cRedirection('true');
      this.loggedIn = this.authService.isLoggedIn();
      this.showMessages();
      this.subscribeToAuthEvents();
      this.subscribeToStateChanges();
      this.subscribeToRouterEvents();
      this.disableBrowserBackButton();
      this.userIdleSubscriber();
      if (this.loggedIn) {
        this.initCapassaAccount();
        this.displayChatBox = true;
      }
    }
  }

  showTrialExpiryMessage(subscription: AccountSubscriptionHistory): void {
    const currentSelectedAccountId = this.sharedStorageService.getAccountId();
    this.sharedStorageService.addSubscriptionId(subscription?.sbscriptionId);
    if (subscription?.typeId === SubscriptionTypes.Default) {
      const trialExpiryMessageVisibility = this.sharedStorageService.getTrialExpiryMessageVisibility(currentSelectedAccountId);
      if (trialExpiryMessageVisibility === 'null') {
        this.openModal();
      } else {
        const item = JSON.parse(trialExpiryMessageVisibility);
        const now = new Date();

        if (now.getTime() > item.expiry) {
          this.sharedStorageService.removeTrialExpiryMessageVisibility(currentSelectedAccountId);
          this.openModal();
        }
      }
    }
  }

  goToSubscription(): void {
    this.router.navigate([RouteLinkConstants.subscription]);
    this.modalService.dismissAll();
  }

  ngAfterViewInit(): void {
    this.activeSubscription = this.appStateService.subscribeActiveSubscription().subscribe((subscription: AccountSubscriptionHistory) => {
      this.showTrialExpiryMessage(subscription);
    });
  }

  openModal(): void {
    this.modalService.open(this.modalTrialExpiryMessage, { centered: true, backdrop: 'static' });
  }

  closeModal(): void {
    this.modalService.dismissAll();
    const currentSelectedAccountId = this.sharedStorageService.getAccountId();
    this.sharedStorageService.addTrialExpiryMessageVisibility(false, currentSelectedAccountId);
  }

  initCapassaAccount(): void {
    const userAccount = this.authService.getAccount();
    const b2CUserId = userAccount?.localAccountId ?? '';
    this.userService.updateB2CUserId(b2CUserId);
    this.isLoading = true;
    this.userDataService.getRegistrationB2cUserById(b2CUserId).subscribe((b2cUser) => {
      if (b2cUser) {
        this.applyNameCleansing(b2cUser);
        this.registrationB2cUser = b2cUser;
        this.appStateService.publishLoggedInB2CUser(b2cUser);
        if (b2cUser.registrationUserId) {
          this.userService.updateB2CUserRegistrationUserId(b2cUser.registrationUserId);
          this.registrationUserId = b2cUser.registrationUserId;
          // Save user logged in time
          this.userService.saveUserLoggedInTime(b2cUser.registrationUserId);
          this.userDataService.updateRegistrationB2cUser(b2cUser).subscribe(() => { });

          // Get and publish logged in capassa user account
          this.userDataService.getUser(b2cUser.registrationUserId).subscribe((capassaRegistrationUser: RegistrationUser) => {
            this.capassaRegistrationUser = capassaRegistrationUser;
            this.copilotUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.copilotUrl + '?profileImageUrl=' + this.capassaRegistrationUser.profileImageUrl);
            this.appStateService.publishRegistrationUser(capassaRegistrationUser);
          });

          // Get and publish onboarded company accounts
          this.userService.setUpCompanyAccountForUser(b2cUser.registrationUserId).subscribe((onBoardCompanyAccounts: Account[] | undefined) => {
            if (onBoardCompanyAccounts === undefined) {
              this.showAccounts = false;
            }
            this.checkIntegrationPermission();

            if (!this.mobileRendering) {
              this.userDataService.getUserPreferences(b2cUser.registrationUserId).subscribe((preferences) => {
                const menuCollapsedPreference = preferences.find(r => r.preferenceType === UserPreferenceType.Sidebar);
                if (menuCollapsedPreference && menuCollapsedPreference.preferenceValue) {
                  const menuCollapsedValues = JSON.parse(menuCollapsedPreference.preferenceValue);

                  this.leftMenuCollapsed = menuCollapsedValues[0].isMenuCollapsed;
                }
                this.appStateService.publishUserPreferences(preferences);
              });
            }
            this.onBoardedAccounts = onBoardCompanyAccounts!;
            this.isLoading = false;

            this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          });
        } else {
          this.isLoading = false;
          this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          this.appStateService.publishCanLoadHome(true);
        }
      }
      else {
        this.saveB2CUser(userAccount).subscribe((savedB2CUser: B2CUser) => {
          this.appStateService.publishLoggedInB2CUser(savedB2CUser);
          this.isLoading = false;
          this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          this.appStateService.publishCanLoadHome(true);
        });
      }
    });
  }

  checkIntegrationPermission(): void {
    this.userActionSubscription = this.appStateService.subscribeUserRoleActions().subscribe(actions => {
      this.canViewIntegrations = actions.find(action => action === UserAction.AddAccountingIntegration) ? true : false;
      if (!this.checkedActiveConnection) {
        this.CheckActiveConnection();
      }
    });
  }

  onSelectedCompanyChanged(selectedCompany: SelectedCompany): void {
    var selectedAccount = selectedCompany.selectedAccount;
    this.appStateService.publishActiveCompanyAccount(selectedAccount);
    this.userPermissionService.loadAccountUserActions(selectedAccount.id);
    this.accountDataService.updateSelectedAccount(selectedAccount);
    this.appStateService.publishAccountingSystemIntegrationStatus(selectedAccount.hasAccountingIntegrated);

    //Set selected company as default if setDefault true
    if (selectedCompany.setDefault) {
      const accountUserSystemRole = new AccountUser();
      accountUserSystemRole.accountId = selectedCompany.selectedAccount.id;
      accountUserSystemRole.isDefaultAccount = true;
      accountUserSystemRole.userId = this.registrationB2cUser.registrationUserId!;
      this.accountDataService.setDefaultAccount(accountUserSystemRole).subscribe();
    }

    if (selectedCompany.selectedAccount.isActive) {
      this.router.onSameUrlNavigation = 'reload';
      //This will indicate that all pre conditions has been met to load home
      this.appStateService.publishCanLoadHome(true);
      this.router.navigateByUrl(RouteLinkConstants.landingPage);
    } else {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl(RouteLinkConstants.inactiveCompany);
    }
  }

  private CheckActiveConnection(): void {
    this.checkedActiveConnection = true;
    this.appStateService.subscribeAccountingSystemIntegrationStatus().subscribe((accountingSystemIntegrated: boolean) => {
      if (accountingSystemIntegrated) {
        this.accountingSystemDataService.getAccountingSystem().subscribe((accountingSystem: AccountingSystem) => {
          if (accountingSystem && !accountingSystem.hasActiveConnection) {
            this.showLostConnectionMessage(accountingSystem);
          }
        });
      }
    });
  }

  showLostConnectionMessage(accountingSystem: AccountingSystem): void {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-dark-backdrop',
      panelClass: 'tm-dialog-panel',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    this.initOverlay(overlayConfig);
    this.appStateService.publishOverlayState(true);
    const connectModalComponent = new ComponentPortal(LostConnectionPopUpComponent);
    const component = this.overlayRef.attach(connectModalComponent);
    component.instance.accountingSystem = accountingSystem;
    component.instance.iconClass = 'icon-update-successful';
    component.instance.messageHeaderKey = 'Reconnect accounting system';
    component.instance.messageContent = this.canViewIntegrations ? 'The connection to your accounting system seems to be lost, please reconnect.' :
      'The connection to your accounting system seems to be lost, you do not have permission to integrate the Accounting system, please reach out to the authorized users.';
    if (this.canViewIntegrations) {
      component.instance.buttonContent1 = 'Reconnect'
    } else {
      component.instance.iSButton1Enable = false;
    };
    component.instance.buttonContent2 = 'Skip';
    component.instance.iconEnable = true;
    component.instance.onClickChange.subscribe(() => {
      this.hideOverlay();
      this.appStateService.publishOverlayState(false);
    });
  }

  initOverlay(config: any = null): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    } else {
      this.overlayRef = config ? this.overlay.create(config) : this.overlay.create();
    }
  }

  hideOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  subscribeToAuthEvents(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.asyncHandler)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.asyncHandler)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idToken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idToken.acr === authConfig.b2cPolicies.names.signUpSignIn || idToken.acr === authConfig.b2cPolicies.names.signUp ||
          idToken.tfp === authConfig.b2cPolicies.names.signUpSignIn || idToken.tfp === authConfig.b2cPolicies.names.signUp
        ) {
          this.msalService.instance.setActiveAccount(payload.account);
          if (!this.loggedIn) {
            this.initCapassaAccount();
            this.loggedIn = true;
            this.displayChatBox = true;
          }
        }

        if (
          idToken.acr === authConfig.b2cPolicies.names.editProfile ||
          idToken.tfp === authConfig.b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.msalService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idToken.oid &&
                account.idTokenClaims?.sub === idToken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  authConfig.b2cPolicies.names.signUpSignIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                  authConfig.b2cPolicies.names.signUpSignIn)
            );

          const signUpSignInFlowRequest: SsoSilentRequest = {
            authority:
              authConfig.b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          this.msalService.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         */
        if (
          idToken.acr === authConfig.b2cPolicies.names.resetPassword ||
          idToken.tfp === authConfig.b2cPolicies.names.resetPassword
        ) {
          const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              authConfig.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...authConfig.apiConfig.scopes],
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };

          this.login(signUpSignInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.asyncHandler)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              authConfig.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        }
      });
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  subscribeToStateChanges(): void {
    const device = this.deviceService.getDevice();
    if (device === Device.Android || device === Device.IOS) {
      this.mobileRendering = true;
    }

    this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
      switch (language) {
        case 'nb':
          this.languageCode = 'nb_NO';
          break;
        default:
          this.languageCode = 'en_US';
          break;
      }
    });

    this.appStateService.subscribeLoadingBarStatus().subscribe((status: string) => {
      switch (status) {
        case LoadingBarStatus.Hide:
          this.userInterfaceService.hide();
          break;
        case LoadingBarStatus.ShowProgressBar:
          this.userInterfaceService.showLoader('', '', false);
          break;
        case LoadingBarStatus.ShowWithoutWording:
          this.userInterfaceService.showLoader('', '');
          break;
        case LoadingBarStatus.Default:
          this.userInterfaceService.showLoader('Loading');
          break;
      }
    });

    this.appStateService.subscribeMenuState().subscribe((menuState: boolean) => {
      this.showResponsiveLeftMenu = menuState;
    });

    this.appStateService.subscribeOnBoardedUserAccount().subscribe((account: Account) => {
      if (!this.onBoardedAccounts) {
        this.onBoardedAccounts = [];
      }
      this.onBoardedAccounts.push(account);
    });

    this.appStateService.subscribeOnBoardedCompanyAccounts().subscribe((onBoardedAccount: Account[]) => {
      this.onBoardedAccounts = onBoardedAccount;
    });

    this.appStateService.subscribeOverlayState().subscribe((overlayState: boolean) => {
      this.isOverlay = overlayState;
    });

    this.registrationB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe(
      (b2cUser: B2CUser) => {
        if (b2cUser && b2cUser.registrationUserId) {
          this.registrationUserId = b2cUser.registrationUserId;
        }
      });

    this.appStateService.subscribeAccountIntegrationStatus().subscribe((accountIntegrationStatus: AccountIntegrationStatus) => {
      if (this.registrationUserId && accountIntegrationStatus.accountId) {
        switch (accountIntegrationStatus.status) {
          case AccountIntegrationStatusEnum.Started:
            this.initializeSignalRHubConnection(accountIntegrationStatus.accountId);
            this.subscribeAccountIntegration(accountIntegrationStatus.accountId);
            break;
        }
      }
    });

    this.appStateService.subscribeAccountingSystemIntegration().subscribe((accountingSystemIntegration: AccountingSystemIntegration) => {
      if (this.registrationUserId && accountingSystemIntegration.accountId) {
        switch (accountingSystemIntegration.status) {
          case AccountingSystemIntegrationStatus.Started:
            this.initializeSignalRHubConnection(accountingSystemIntegration.accountId);
            break;
        }
      }
    });

    this.appStateService.subscribeShowFullScreenState().subscribe((fullScreen: boolean) => {
      this.showFullScreen = fullScreen;
    });
  }

  subscribeToRouterEvents(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        event = event as NavigationStart;
        this.showAccounts = true;
      }
    });
  }

  onActivateOverlayOutlet(event: any): void {
    Promise.resolve().then(() => this.isOverlay = true);
  }

  onDeactivateOverlayOutlet(event: any): void {
    Promise.resolve().then(() => this.isOverlay = false);
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    this.authService.signIn(userFlowRequest);
  }

  logout(): void {
    this.authService.signOut();
    this.displayChatBox = false;
  }

  public saveB2CUser(userAccount: AccountInfo | null): Observable<B2CUser> {
    const b2CUserId = userAccount?.localAccountId;

    const registrationB2CUser = new B2CUser();
    if (b2CUserId && userAccount != null && userAccount.idTokenClaims) {
      registrationB2CUser.id = b2CUserId;
      const firstName =  userAccount.idTokenClaims['given_name'];
      registrationB2CUser.firstName = firstName != null? firstName as string: userAccount.idTokenClaims.name;
      registrationB2CUser.lastName = userAccount.idTokenClaims['family_name'] as string;      
      registrationB2CUser.name = userAccount.idTokenClaims.name;
      registrationB2CUser.email = userAccount.idTokenClaims.emails ? userAccount.idTokenClaims.emails[0] : '';
      registrationB2CUser.idp = userAccount.idTokenClaims.idp ? userAccount.idTokenClaims.idp : Constants.Email;
      registrationB2CUser.iss = userAccount.idTokenClaims.iss ? userAccount.idTokenClaims.iss : '';
      registrationB2CUser.iat = userAccount.idTokenClaims.iat;
      registrationB2CUser.nbf = userAccount.idTokenClaims.nbf;
      registrationB2CUser.exp = userAccount.idTokenClaims.exp;
      registrationB2CUser.authTime = userAccount.idTokenClaims['authTime'] as number;
      registrationB2CUser.oid = userAccount.idTokenClaims.oid;
      registrationB2CUser.sub = userAccount.idTokenClaims.sub;
      registrationB2CUser.tfp = userAccount.idTokenClaims['tfp'] as string;
      registrationB2CUser.nonce = userAccount.idTokenClaims.nonce;
      registrationB2CUser.ver = userAccount.idTokenClaims.ver ? userAccount.idTokenClaims.ver : '';
      registrationB2CUser.createdOn = new Date();
      registrationB2CUser.createdBy = b2CUserId;
      this.applyNameCleansing(registrationB2CUser);
    }
    return this.userDataService.saveRegistrationB2cUser(registrationB2CUser).pipe(map((savedB2CUser: B2CUser) => savedB2CUser));
  }

  loadCurrentLanguage(): void {
    const language = this.languageService.getLanguage();
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.appStateService.publishSelectedLanguage(language);
  }

  showMessages(): void {
    this.userFlow = this.userFlowService.getFlow();

    switch (this.userFlow) {
      case UserFlow.Invitation:
      case UserFlow.MobileSignIn:
      case UserFlow.MobileSignUp:
      case UserFlow.SignIn:
        if (this.loggedIn) {
          this.userInterfaceService.showLoader('Welcome back', 'Keep working with Your Digital CFO');
        }
        else {
          this.userInterfaceService.showLoader('Loading');
        }
        break;
      case UserFlow.SignUp:
        if (this.loggedIn) {
          this.userInterfaceService.showLoader('Getting started', 'You\'re here! The day just got better');
        }
        else {
          this.userInterfaceService.showLoader('Loading');
        }
        break;
    }

    this.activeRoute.queryParams.subscribe(params => {
      this.accountingSystemName = params.accountingSystemName ? params.accountingSystemName : this.accountingSystemName;
      this.containerState = JSON.parse(params.state ? params.state : null);
      this.userIdentifier = params.userIdentifier ? params.userIdentifier : this.userIdentifier;

      if (this.containerState && !isNaN(this.containerState) && this.containerState >= 0) {
        switch (this.containerState) {
          case OnboardingContainerState.SSNAlreadyUsed:
            if (this.userIdentifier && this.userIdentifier !== '') {
              this.userInterfaceService.showLoader(this.pleaseWaitText, this.weAreConnectingText);
            }
            break;
          case OnboardingContainerState.IntegrationSuccess:
            if (this.accountingSystemName === AccountingSystemEnum.Fiken) {
              this.userInterfaceService.showLoader(this.pleaseWaitText, 'We are connecting your Fiken account with Capassa');
            }
            else if (this.accountingSystemName === AccountingSystemEnum.Visma) {
              this.userInterfaceService.showLoader(this.pleaseWaitText, 'We are connecting your Visma account with Capassa');
            }
            break;
        }
      }
    });
  }

  onMenuChanged(selection: string): void {
    this.selectedSection = selection;
    if (this.onMasterMenuItemSelected) {
      this.onMasterMenuItemSelected(this.selectedSection);
    }
  }

  disableBrowserBackButton(): void {
    window.history.pushState(null, '', null);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.browserStateService.routeTarget !== event.url) {
          window.history.pushState(null, '', null);
        }
      }
    });

    window.addEventListener('popstate', (event) => {
      if (event.state) {
        this.appStateService.publishBrowserState(event.state);
        window.history.pushState(null, '', null);
      } else {
        window.history.pushState(null, '', null);
      }
    });
  }

  userIdleSubscriber(): void {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(() => this.logout());
  }

  localeOverride(): void {
    moment.updateLocale('nb', {
      monthsShort: 'jan._feb._mar_apr._mai_jun_jul_aug._sep._okt._nov._des.'.split('_')
    });
  }

  initializeSignalRHubConnection(accountId: string) {
    this.signalRMessageSubscriberService.initializeHubConnection(this.registrationUserId, accountId);
    this.signalRMessageSubscriberService.receiveMessages();
  }

  subscribeAccountIntegration(selectedAccountId: string): void {
    this.signalRMessageSubscriberService.subscribeNotificationChannel().subscribe((message: GlobalNotification) => {
      if (message && message.data) {
        const notificationMessage = message.data;
        if (selectedAccountId && selectedAccountId === notificationMessage.AccountId) {
          if (message.typeId === NotificationType.TransformSyncData &&
            notificationMessage.SyncStatusId === SyncStatus.Completed) {
            switch (notificationMessage.MessageKey) {
              case NotificationKeys.TRANSFORM_ONBOARDING_ACCOUNT_COMPLETED_KEY:
                const accountIntegrationStatus = new AccountIntegrationStatus();
                accountIntegrationStatus.accountId = selectedAccountId;
                accountIntegrationStatus.status = AccountIntegrationStatusEnum.Completed;
                this.appStateService.publishAccountIntegrationStatus(accountIntegrationStatus);
                break;
            }
          }
        }
      }
    });
  }

  onMenuCollapsed(menuCollapsed: boolean): void {
    this.leftMenuCollapsed = menuCollapsed;
    this.appStateService.publishMenuToggleEvent(menuCollapsed);
  }

  toggleCopilot(): void {
    this.isCopilotMinimized = !this.isCopilotMinimized;
  }

  applyNameCleansing(b2cUser: B2CUser):void{
    if(b2cUser.firstName && b2cUser.lastName && b2cUser.firstName.includes(b2cUser.lastName)){
      b2cUser.firstName = b2cUser.firstName.replace(` ${b2cUser.lastName}`, "");
    }
  }
}
