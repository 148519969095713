<div class="block-report-share" *ngIf="showShareWindow && !shareToDataRoom">
    <div class="block-header">
        <h2 class="h2">{{title | translate}}</h2>
        <div class="btn-close" (click)='closePopup()'></div>
    </div>

    <!-- Tabs for Sharing and Content -->
    <div class="modal-tabs tabs">
        <button class="tab-button" [ngClass]="{'active': activeTab === 'sharing'}"
            (click)="activeTab = 'sharing'">{{'Sharing' | translate}}</button>
        <!-- <button class="tab-button" [ngClass]="{'active': activeTab === 'content'}"
            disabled
            >{{'Content' | translate}}</button> -->
    </div>

    <div class="block-body">
        <!-- Sharing Tab Content -->
        <div *ngIf="activeTab === 'sharing'">
            <div class="block-email-body with-tabs">
                <div class="block-distribution-list">
                    <div class="highlights-trigger distribution-list-trigger">
                        <div class="bl-left">
                            <h3 class="h3">{{'Distribution list' | translate}}</h3>
                        </div>

                        <div class="ctrl">
                            <div class="radio" [ngClass]="{'on': distributionListExpand}"
                                (click)="distributionlListToggleSection(distributionListExpand)">
                                <div class="radio-btn"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-add-email">
                    <div class="col1">{{'To' | translate}}</div>
                    <div class="col2">
                        <span class="email" *ngFor="let emailRecipient of emailList"
                            [ngClass]="{'invalid':!emailRecipient.isValid}"> {{emailRecipient.email}} <span
                                class="btn-close" (click)="removeEmail(emailRecipient.id)"></span></span>
                        <input class="enter-email" placeholder="{{ emailPlaceHolder | translate}}" type="text"
                            list="distributionList" [(ngModel)]="recipient" (keyup.enter)="addEmail()"
                            (keydown.tab)="addEmail()" (focusout)="addEmail()">
                        <datalist id="distributionList" *ngIf="distributionListExpand">
                            <option *ngFor="let email of recentEmailDistributionListValues" [value]="email">{{email}}
                            </option>
                        </datalist>
                    </div>
                    <div class="button-wrapper">
                        <button *ngIf="distributionListExpand"
                            [disabled]="disableSubmit() || showLoader || disableDistributionList"
                            [ngClass]="{'loading-on':showLoader, 'disabled':disableDistributionList}"
                            class="btn btn-secondary btn-action save-report"
                            (click)="updateEmailDistributionListPreference()">{{
                            'Save'| translate}} <span class="icon"></span></button>
                    </div>
                </div>

                <div class="block-email-body">
                    <textarea placeholder="{{'Type your message here.' | translate}}" [(ngModel)]="message"
                        class="email-text" name="" id="" cols="30" rows="4"></textarea>
                </div>
                <!-- Sharing Options -->
                <div class="block-sharing-options">
                    <div class="highlights-trigger">
                        <div class="b-left section-heading">
                            <h3 class="h3">{{'Sharing options' | translate}}</h3>
                        </div>
                        <div class="share-file-types bl-left">
                            <input type="radio" id="share-excel" [(ngModel)]="fileType" name="share-doc-type"
                                value="Excel">
                            <label class="radio-button-label" for="share-excel">{{'Excel file' | translate}}</label>
                            <input type="radio" id="share-pdf" [(ngModel)]="fileType" name="share-doc-type" value="PDF">
                            <label class="radio-button-label" for="share-pdf">{{'PDF file' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content Tab Content -->
        <div class="content-tab" *ngIf="activeTab === 'content'">
            <div class="block-email-body block-email-body-content with-tabs">
                <div class="block-add-email" [ngClass]="{'expanded': sections.executiveSummary}">
                    <div class="block-add-email-header">
                        <div class="toggle">
                            <div class="toggle-switch expanded">
                            </div>
                        </div>
                        <div class="col1">
                            <h4 (click)="toggleSection('executiveSummary')">{{'Executive Summary' | translate}}</h4>
                            <div class="ctrl">
                                <div class="radio">
                                    <div class="radio-btn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col2" *ngIf="sections.executiveSummary">
                        <textarea placeholder="{{'Type your summary here.' | translate}}" [(ngModel)]="executiveSummary"
                            class="email-text" name="" id="" cols="30" rows="4"></textarea>
                        <div class="block-add-email">
                            <div class="col1">{{'Compare Numbers' | translate}}</div>
                            <div class="col2 ui-controls-grid">
                                <div class="checkbox">
                                    <input type="checkbox" id="revenue-checkbox" class="checkbox__box" />
                                    <label for="revenue-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Revenue
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="gross-profit-checkbox" class="checkbox__box" />
                                    <label for="gross-profit-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Gross profit
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="net-fin-inc-exp-checkbox" class="checkbox__box" />
                                    <label for="net-fin-inc-exp-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Net financial income/expenses
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="cost-of-sales-checkbox" class="checkbox__box" />
                                    <label for="cost-of-sales-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Cost of sales
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="op-profit-checkbox" class="checkbox__box" />
                                    <label for="op-profit-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Operating profit
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="net-profit-before-tax-checkbox" class="checkbox__box" />
                                    <label for="net-profit-before-tax-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Net profit before tax
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Section -->
            <div class="block-email-body block-email-body-content with-tabs">
                <div class="block-add-email" [ngClass]="{'expanded': sections.table}">
                    <div class="block-add-email-header" (click)="toggleSection('table')">
                        <div class="toggle">
                            <div class="toggle-switch expanded">
                            </div>
                        </div>
                        <div class="col1">
                            <h4>{{'Table' | translate}}</h4>
                            <div class="ctrl">
                                <div class="radio">
                                    <div class="radio-btn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col2" *ngIf="sections.table">
                        <div class="block-add-email no-border-bottom">
                            <div class="flex-row">
                                <input type="radio" id="share-excel" name="detailed-report-radio" value="Excel file">
                                <label class="radio-button-label" for="detailed-report-radio">Detailed report</label>
                                <input type="radio" id="share-pdf" name="overview-report-radio" value="PDF file">
                                <label class="radio-button-label" for="overview-report-radio">Overview report</label>
                            </div>
                        </div>
                        <div class="block-add-email">
                            <div class="col1">Compare To</div>
                            <div class="col2 ui-controls-grid">
                                <div class="checkbox">
                                    <input type="checkbox" id="last-year-checkbox" class="checkbox__box" />
                                    <label for="last-year-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Last Year
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="last-month-checkbox" class="checkbox__box" />
                                    <label for="last-month-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Last Month
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="budget-checkbox" class="checkbox__box" />
                                    <label for="budget-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Budget
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Graphs Section -->
            <div class="block-email-body block-email-body-content with-tabs">
                <div class="block-add-email" [ngClass]="{'expanded': sections.graphs}">
                    <div class="block-add-email-header" (click)="toggleSection('graphs')">
                        <div class="toggle">
                            <div class="toggle-switch expanded">
                            </div>
                        </div>
                        <div class="col1">
                            <h4>{{'Graphs' | translate}}</h4>
                            <div class="ctrl">
                                <div class="radio">
                                    <div class="radio-btn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col2" *ngIf="sections.graphs">
                        <div class="block-add-email">
                            <div class="col2 ui-controls-grid">
                                <div class="checkbox">
                                    <input type="checkbox" id="profit-loss-checkbox" class="checkbox__box" />
                                    <label for="profit-loss-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Profit & loss
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="cash-balance-checkbox" class="checkbox__box" />
                                    <label for="cash-balance-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Cash balance
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="roe-roce-checkbox" class="checkbox__box" />
                                    <label for="roe-roce-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        ROE and ROCE
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="op-exp-cat-checkbox" class="checkbox__box" />
                                    <label for="op-exp-cat-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Operating expenditure by category
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="current-asst-liab-checkbox" class="checkbox__box" />
                                    <label for="current-asst-liab-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Current assets/liabilities
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="roa-checkbox" class="checkbox__box" />
                                    <label for="roa-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        ROA
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Key Financials Section -->
            <div class="block-email-body block-email-body-content with-tabs">
                <div class="block-add-email" [ngClass]="{'expanded': sections.keyFinancials}">
                    <div class="block-add-email-header" (click)="toggleSection('keyFinancials')">
                        <div class="toggle">
                            <div class="toggle-switch expanded">
                            </div>
                        </div>
                        <div class="col1">
                            <h4>{{'Key Financials' | translate}}</h4>
                            <div class="ctrl">
                                <div class="radio">
                                    <div class="radio-btn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col2" *ngIf="sections.keyFinancials">
                        <div class="block-add-email">
                            <div class="col2 ui-controls-grid">
                                <div class="checkbox">
                                    <input type="checkbox" id="revenue-checkbox" class="checkbox__box" />
                                    <label for="revenue-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Revenue
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="gross-profit-checkbox" class="checkbox__box" />
                                    <label for="gross-profit-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Gross profit
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="net-fin-inc-exp-checkbox" class="checkbox__box" />
                                    <label for="net-fin-inc-exp-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Net financial income/expenses
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="cost-of-sales-checkbox" class="checkbox__box" />
                                    <label for="cost-of-sales-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Cost of sales
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="op-profit-checkbox" class="checkbox__box" />
                                    <label for="op-profit-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Operating profit
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="net-profit-before-tax-checkbox" class="checkbox__box" />
                                    <label for="net-profit-before-tax-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Net profit before tax
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="working-capital-checkbox" class="checkbox__box" />
                                    <label for="working-capital-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Working capital
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="current-ratio-checkbox" class="checkbox__box" />
                                    <label for="current-ratio-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Current ratio
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="ebit-margin-checkbox" class="checkbox__box" />
                                    <label for="ebit-margin-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        EBIT margin
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="quick-ratio-checkbox" class="checkbox__box" />
                                    <label for="quick-ratio-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Quick ratio
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="ebitda-margin-checkbox" class="checkbox__box" />
                                    <label for="ebitda-margin-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        EBITDA margin
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input type="checkbox" id="employee-count-checkbox" class="checkbox__box" />
                                    <label for="employee-count-checkbox" class="checkbox__label">
                                        <span class="checkbox__tick"></span>
                                        Employee count
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Company Highlights Section -->
            <div class="block-email-body block-email-body-content with-tabs">
                <div class="block-add-email" [ngClass]="{'expanded': sections.companyHighlights}">
                    <div class="block-add-email-header" (click)="toggleSection('companyHighlights')">
                        <div class="toggle">
                            <div class="toggle-switch expanded">
                            </div>
                        </div>
                        <div class="col1">
                            <h4>{{'Company Highlights' | translate}}</h4>
                            <div class="ctrl">
                                <div class="radio">
                                    <div class="radio-btn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col2" *ngIf="sections.companyHighlights">
                        <textarea placeholder="{{'Type your highlights here.' | translate}}"
                            [(ngModel)]="companyHighlights" class="email-text" name="" id="" cols="30"
                            rows="4"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="save-report-settings checkbox">
            <input type="checkbox" id="checkbox1" class="checkbox__box" />
            <label for="checkbox1" class="checkbox__label">
                <span class="checkbox__tick"></span>
                Save report settings
            </label>
        </div> -->

        <p class="p">
            {{description | translate}}
        </p>
        <button [disabled]="disableSend() || showLoader" [ngClass]="{'loading-on':showLoader}"
            class="btn btn-primary btn-block btn-share upload-icon-outline-white"
            (click)="fileType =='PDF'? sharePDFReport() : shareExcelReport()">{{
            'Send report'| translate}} <span class="icon"></span></button>
        <button [disabled]="fileType =='PDF'" class="btn btn-secondary btn-block btn-share download-icon-outline"
            (click)="downloadReport()">{{'Download report'| translate}}<span class="icon"></span>
        </button>
    </div>
</div>
<div class="block-report-share" [ngClass]="!shareToDataRoom || showErrorMessage ? 'hide-grid-container' : ''">
    <div class="block-header">
        <h2 class="h2">{{title | translate}}</h2>
        <div class="btn-close" (click)='closePopup()'></div>
    </div>
    <div class="block-body">
        <div class="block-detailed-report-to-data-room"
            *ngIf="!isBudget && pdfReportType === pdfReportTypeEnum.profitAndLoss || pdfReportType === pdfReportTypeEnum.balanceSheet || pdfReportType === pdfReportTypeEnum.liquidity">
            <div class="highlights-trigger detailed-report-trigger">
                <div class="bl-left">
                    <h3 class="h3">{{'Detailed report' | translate}}</h3>
                </div>

                <div class="ctrl">
                    <div class="radio" [ngClass]="{'on': detailedReport}"
                        (click)="toggleDetailedReportSection(detailedReport)">
                        <div class="radio-btn"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="share-to-data-room-pop-up">
            <div id="dataRoomTree"></div>
        </div>
        <p class="p">
            {{description | translate}}
        </p>
        <button [disabled]="showLoader" [ngClass]="{'loading-on':showLoader}"
            class="btn btn-primary btn-block btn-share upload-to-data-room-icon-outline-white"
            (click)="!isBudget ? sharePDFReport() : shareBudget()">{{'Send report'| translate}} </button>
    </div>
</div>

<div class="capassa-modal" *ngIf="showErrorMessage">
    <div class="capassa-modal-content">
        <div class="icon-share-unsuccess"></div>
        <h1 class="h1">{{ 'Sharing unsuccessful!' | translate }}</h1>
        <div class="app-message error-message">
            {{ 'Unfortunately, the sharing was unsuccessful. Please try again.' | translate }}
        </div>
        <button class="btn btn-primary" (click)="shareAgain()">
            {{ 'Share again' | translate }}
        </button>
        <button class="btn btn-secondary" (click)="closeErrorModal()">
            {{ 'Close' | translate }}
        </button>
    </div>
</div>